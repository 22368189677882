.table th,
.table td {
  padding-right: 10px;
}

.paginator-wrapper{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;
}
.paginator{
  display: flex;
  list-style: none;
  gap: 15px;
  font-size: 18px;
}
.paginator-active{
  font-weight: 700;
  color: blue;
}
/* .ApplicantsTable th,
.ApplicantsTable td {
  padding-right: 10px;
} */

/* .ApplicantsTable {
  border-spacing: 0;
  border: 1px solid black;
} */

/* .thead {
  overflow-y: auto;
  overflow-x: hidden;
}

.tbody {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 250px;
}
.tr {
  border-bottom: 1px solid black;
}
.tr:last-child.td {
  border-bottom: 0;
} */
